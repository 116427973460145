<template>
  <div class="wrapper">
    <ctheader></ctheader>
    <div class="container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="名称">
          <el-input v-model="formInline.name" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="code">
          <el-input v-model="formInline.code" placeholder="code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add(0)">新增</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 20px">
        <el-radio-group v-model="genre" @change="genreChange">
          <el-radio :label="-1" border>企业端</el-radio>
          <el-radio :label="0" border>市场端</el-radio>
        </el-radio-group>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="getRowClass"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="title" label="菜单名字" width="200">
        </el-table-column>
        <el-table-column prop="name" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type == 3 ? "按钮" : "页面" }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"> </el-table-column>
        <el-table-column prop="code" label="code"> </el-table-column>
        <el-table-column prop="show_id" label="show_id"> </el-table-column>
        <el-table-column prop="path" label="path"> </el-table-column>
        <el-table-column prop="component" label="component"> </el-table-column>
        <el-table-column prop="keepAlive" label="keepAlive"> </el-table-column>
        <el-table-column prop="hidden" label="是否隐藏">
          <template slot-scope="scope">
            {{ scope.row.hidden == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="detail(scope.row)">查看</el-button>
            <el-button type="text" size="small" @click="add(scope.row)">新增子级</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="菜单信息" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        label-width="100px"
        class="dialog-form"
        label-position="right"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="类型">
              <el-radio-group v-model="form.type">
                <el-radio :label="1">菜单</el-radio>
                <el-radio :label="3">按钮</el-radio>
                <el-radio :label="2">隐藏页面</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称">
              <el-input v-model="form.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序">
              <el-input v-model="form.sort" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="图标名称">
              <el-input v-model="form.icon" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上级菜单id">
              <el-input v-model="form.pid" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="code">
              <el-input v-model="form.code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="show_id">
              <el-input v-model="form.show_id" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="component">
              <el-input v-model="form.component" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="path">
              <el-input v-model="form.path" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="keepAlive">
              <el-switch
                v-model="form.keepAlive"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否隐藏">
              <el-switch
                v-model="form.hidden"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="noCache">
              <el-switch
                v-model="form.noCache"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.pid!=0">
            <el-form-item label="name">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save" :disabled="disabled"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";
import { MenuServe } from "./api";

export default {
  name: "",
  components: { ctheader },
  mixins: [],
  props: {},
  data() {
    return {
      formInline: {},
      tableData: [],
      genre: -1,
      dialogFormVisible: false,
      form: {},
      disabled: false,

    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getMenuData();
  },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    async getMenuData() {
      this.formInline.action = "menu.lists";
      this.formInline.genre = this.genre;
      const { code, data } = await MenuServe(this.formInline);
      if (code == 0) {
        this.tableData = data;
      }
    },
    async save() {
      console.log(this.form);
      this.form.genre = this.genre;
      this.form.action = "add.menu";
      const { code, msg } = await MenuServe(this.form);
      if (code == 0) {
       this.$message.success(msg);
        this.dialogFormVisible = false;
        this.getMenuData();
      }

    },

    genreChange() {
      this.getMenuData();
    },
    query() {
      this.getMenuData();
    },
    add(val) {
      console.log(val)
      this.disabled = false;
      this.form.pid = val ? val.id : 0;
      this.dialogFormVisible = true;
    },
    detail(val) {
      console.log(val)
      this.form =val
      this.disabled = true;
      this.dialogFormVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-input {
    width: 300px;
  }
}
.dialog-form {
  .el-input {
    width: 90%;
  }
}
</style>
